/* You can add global styles to this file, and also import other style files */

.MainHeaderWrapper_header_bg__CXa1e {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .5s ease-in-out;
    width: 100%;
    z-index: -1;
}
.MainHeaderWrapper_animate__CLDjY {
    animation: MainHeaderWrapper_zoomin__X66yk 4s ease-in;
}
.MainHeaderWrapper_header_bg__CXa1e:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(transparent, rgba(48, 48, 48, 1));
    opacity: .7;
    transition: .5s;
}
.overflow {
    overflow: hidden;
}
.title_white {
    color: #fff;
    font-size: 2.5rem !important;
    line-height: 1.5 !important;
    text-transform: capitalize;
    font-weight: 700;
    width: 70%
}

@media screen and (max-width: 426px) {
    .title_white {
        font-size: 1.8rem !important;
    }
}